<template>
  <v-container
    :key="this.$route.params.uuid"
    class="fill-height"
    fluid
  >
    <v-row>
      <v-col
        md="4"
        lg="6"
      >
        <v-card
          :loading="loading.lab_result"
          min-height="500"
        >
          <template>
            <v-card-title class="d-flex justify-space-between">
              <div>Lab Result <span v-if="lab_result.external_id">{{ lab_result.external_id }}</span></div>
              <p
                v-if="lab_result.client"
                class="text-overline"
              >
                {{ lab_result.client.name }}
              </p>
            </v-card-title>
            <v-card-text>
              <v-card outlined>
                <!-- <embed
                  :src="pdf"
                  style="width:100%; min-height:600px"
                > -->
                <object
                  :data="lab_result.media.filter(x => x.collection_name == 'pdf')[0].original_url"
                  type="application/pdf"
                  width="100%"
                  height="600px"
                />
                <!-- <pdf
                  ref="pdf"
                  :src="pdf"
                  :page="page"
                  @num-pages="numPages = $event"
                  style="width:100%; max-height:50%"
                /> -->
                <!-- <v-divider />
                <v-card-actions>
                  <v-btn
                    @click="page > 1 ? page-- : 1"
                  >
                    <v-icon>fal fa-caret-left</v-icon>
                  </v-btn>
                  <v-btn
                    @click="page < numPages ? page++ : 1"
                  >
                    <v-icon>fal fa-caret-right</v-icon>
                  </v-btn>
                </v-card-actions> -->
              </v-card>
            </v-card-text>
          </template>
        </v-card>
      </v-col>
      <v-col
        md="8"
        lg="6"
      >
        <v-card :loading="loading.lab_result">
          <status-notes
            v-if="['needs review', 'contact lab', 'tech support'].includes(lab_result.status)"
            :statuses="lab_result.statuses"
          />
          <v-card-text>
            <demographics
              :lab-result="lab_result"
              :loading="loading.lab_result"
              short-diagnosis
              class="mb-4"
            />
            <v-card
              outlined
              class="mb-4"
            >
              <v-card-title class="d-flex justify-space-between">
                <span class="text-subtitle-1 mt-n5">
                  Organisms
                </span>
                <v-checkbox
                  v-model="data.negative"
                  label="Negative"
                  class="mt-0 pt-0"
                  dense
                />
              </v-card-title>
              <v-card-text>
                <template
                  v-for="(item, key) in data.organisms"
                >
                  <v-text-field
                    :key="key"
                    v-model.trim="data.organisms[key].name"
                    clearable
                    dense
                    @keyup="addOrganism"
                  />
                </template>
              </v-card-text>
            </v-card>
            <v-card
              outlined
            >
              <v-card-title class="d-flex justify-space-between">
                <span class="text-subtitle-1 mt-n5">
                  Resistance
                  <div class="text-caption">
                    Enter one gene from each category
                  </div>
                </span>
                <v-checkbox
                  v-model="data.no_resistance_tested"
                  label="Not Tested"
                  class="mt-0 pt-0"
                  dense
                />
              </v-card-title>
              <v-card-text>
                <template
                  v-for="(item, key) in data.resistance"
                >
                  <v-text-field
                    :key="key"
                    v-model.trim="data.resistance[key].gene"
                    clearable
                    dense
                    @keyup="addResistance"
                  >
                    <template
                      #prepend-inner
                      class="my-0"
                    >
                      <v-checkbox
                        v-model.trim="data.resistance[key].detected"
                        class="mb-n3"
                        dense
                        title="Detected"
                      />
                    </template>
                  </v-text-field>
                </template>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-col>
              <flag-for-review
                :key="`flag:${data.uuid}`"
                :uuid="data.uuid"
              />
            </v-col>
            <v-col class="text-no-wrap shrink">
              <v-btn
                color="purple"
                :loading="loading.button.interpretation"
                :disabled="data.negative"
                :dark="!data.negative"
                class="mr-2"
                @click="submit(true)"
              >
                <i class="fal fa-file-magnifying-glass mr-1" />
                {{ $t('Submit') }} & Interpret
              </v-btn>
              <v-btn
                color="cyan"
                :loading="loading.button.submit"
                dark
                @click="submit(false)"
              >
                <i class="fal fa-check mr-1" />
                {{ $t('Submit') }}
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  export default {
    metaInfo () {
      return {
        title: 'Lab Result ' + this.lab_result.external_id + ' | Data Extraction',
      }
    },
    components: {
      Demographics: () => import('@/components/lab_result/Demographics.vue'),
      FlagForReview: () => import('@/components/lab_result/FlagForReview.vue'),
      StatusNotes: () => import('@/components/lab_result/StatusNotes.vue'),
    },
    data: () => ({
      loading: {
        lab_result: false,
        button: {
          interpretation: false,
          submit: false,
        },
        pdf: false,
        organisms: false,
        resistance: false,
      },
      valid: true,
      lab_result: {},
      pdf: null,
      numPages: null,
      page: 1,
      organisms: [],
      search: {
        organisms: '',
        resistance: '',
      },
      data: {},
    }),
    watch: {
      'search.organisms' (val) {
        this.fetchOrganisms(val)
        // if (array) {
        //   for (let i = 0; i < array.length; ++i) {
        //     if (array[i] && this.interpretation.organisms[i].drug !== undefined && this.interpretation.organisms[i].drug.name.en !== array[i]) {
        //       this.fetchOrganisms(i, array[i])
        //     }
        //   }
        // }
      },
    },
    created () {
      this.fetchLabResult()
    },
    methods: {
      submit (interpret) {
        this.loading.button[interpret ? 'interpretation' : 'submit'] = true
        this.axios.post('lab_results/' + this.$route.params.uuid + '/extraction', this.data)
          .then((response) => {
            if (interpret) {
              this.$router.push({ name: 'Interpretation', params: { uuid: this.$route.params.uuid } })
            } else if (response.data.uuid != this.$route.params.uuid) {
              this.setLabResult(response.data)
              this.$router.push({ name: 'Data Extraction', params: { uuid: response.data.uuid } })
            } else {
              this.$router.push({ name: 'Lab Results' })
            }
          })
          .catch(error => {
            if (error.response.status === 422) {
              Object.entries(error.response.data).forEach((error) => {
                this.$toast.error(error)
              })
            } else {
              this.$toast.error(error)
            }
          })
          .finally(() => { this.loading.button[interpret ? 'interpretation' : 'submit'] = false })
      },
      fetchLabResult () {
        this.loading.lab_result = true
        const promise = this.axios.get('lab_results/' + this.$route.params.uuid)
        return promise.then((response) => {
          this.setLabResult(response.data)
        })
        .catch(error => {
          this.$toast.error(error)
        })
        .finally(() => { this.loading.lab_result = false })
      },
      setLabResult(data) {
        this.data = {
          negative: false,
          no_resistance_tested: false,
          organisms: [
            {
              name: '',
              detected: true,
            },
          ],
          resistance: [
            {
              gene: '',
              detected: true,
            },
          ],
        },
        this.$set(this, 'lab_result', data)
        this.$set(this.data, 'organisms', data.organisms.map(x => ({ name: x.name, detected: x.result.detected })).concat(this.data.organisms))
        this.$set(this.data, 'resistance', data.resistance_genes.map(x => ({ gene: x.gene, detected: x.result.detected })).concat(this.data.resistance))
      },
      fetchOrganisms (search) {
        this.loading.organisms = true
        this.axios.get('admin/organisms', { params: { search: search } })
          .then((res) => {
            this.organisms = res.data.data
          })
          .catch(error => {
            if (error.response?.data?.message) {
              this.$toast.error(error.response.data.message)
            }
          })
          .finally(() => { this.loading.organisms = false })
      },
      addOrganism () {
        if (this.data.organisms[this.data.organisms.length - 1].name !== '') {
          this.data.organisms.push({
            name: '',
            detected: true,
          })
        }
      },
      addResistance () {
        if (this.data.resistance[this.data.resistance.length - 1].gene !== '') {
          this.data.resistance.push({
            gene: '',
            detected: true,
          })
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
.inner-text {
  height: 100%;
  width: 100%;
  font-size: 4.5em;
  padding-top: 0.9em;

  span {
    max-width: 100px;
  }
}
.v-dialog {
  position: absolute;
  right: 0;
}
</style>
